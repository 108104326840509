import React, { createContext, useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { usePortalApi } from '../const/api-backend';
import { UserDataContext } from './userDataContext';

const WelcomePageConfigContext = createContext();

const WelcomePageConfigProvider = ({ children }) => {
  const { selectedBusinessUnit, sessionContext } = useContext(UserDataContext);

  const [strapiWelcomePageConfig, setStrapiWelcomePageConfig] = useState(null);
  const [pinnedTravelAlert, setPinnedTravelAlert] = useState(null);
  const [brand, setBrand] = useState(null);
  const [quickLinks, setQuickLinks] = useState(null);
  const [isConfigLoading, setIsConfigLoading] = useState(true);
  const [headerBanner, setHeaderBanner] = useState(null);
  const [contactUsContent, setContactUsContent] = useState(null);
  const [lmsLink, setLmsLink] = useState(null);
  const [eClaimsLink, setEClaimsLink] = useState(null);
  const [bookABdmLink, setBookABdmLink] = useState(null);
  const [error, setError] = useState(null);

  const API = usePortalApi();
  useEffect(() => {
    const fetchData = async () => {
      if (Array.isArray(selectedBusinessUnit)) return;
      try {
        const { country } = selectedBusinessUnit;
        if (country === null)
          throw new Error('Country is not assigned to businessUnit');
        const countryCode = country.country;

        if (selectedBusinessUnit === null)
          throw new Error('SelectedBusinessUnit is null');

        const { brand } = selectedBusinessUnit;
        setIsConfigLoading(true);
        const path = `/welcome-page-config/${brand}/${countryCode}`;

        const {
          data: {
            data: { attributes },
          },
        } = await API.get(path);
        const {
          brand: brandResponse,
          dynamicQuickLinks,
          pinnedTravelAlert,
          headerBanner: headerBannerResponse,
          contactUs: contactUsContent,
          lmsLink,
          eClaimsLink,
          bookABdmLink,
        } = attributes;

        setStrapiWelcomePageConfig(attributes);
        setPinnedTravelAlert(pinnedTravelAlert);
        setBrand(brandResponse);
        setQuickLinks(dynamicQuickLinks);
        setHeaderBanner(headerBannerResponse);
        setContactUsContent(contactUsContent);
        setLmsLink(lmsLink);
        setEClaimsLink(eClaimsLink);
        setBookABdmLink(bookABdmLink);
      } catch (error) {
        console.error('Error fetching data:', error);
        setError(error);
      } finally {
        setIsConfigLoading(false);
      }
    };

    fetchData();
  }, [sessionContext]);

  return (
    <WelcomePageConfigContext.Provider
      value={{
        strapiWelcomePageConfig,
        pinnedTravelAlert,
        brand,
        quickLinks,
        contactUsContent,
        lmsLink,
        eClaimsLink,
        bookABdmLink,
        isConfigLoading,
        error,
        headerBanner,
      }}
    >
      {children}
    </WelcomePageConfigContext.Provider>
  );
};

WelcomePageConfigProvider.propTypes = {
  children: PropTypes.node,
};

export { WelcomePageConfigProvider, WelcomePageConfigContext };
