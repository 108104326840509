import React, { useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { getToken } from 'shared/const/token.helper';
import { reportError } from 'shared/services/raygunService';
import { BRAND, USER_ROLE as ROLE } from '../const/roles.const';
import { NotificationContext } from '../../shared/context/notificationContext';

export const UserDataContext = React.createContext(undefined);

export const UserDataProvider = ({ children }) => {
  const [userData, setUserData] = useState(null);

  const [brands, setBrands] = useState([]);
  const [selectedBrand, setSelectedBrand] = useState([]);

  const [businessUnits, setBusinessUnits] = useState([]);
  const [selectedBusinessUnit, setSelectedBusinessUnit] = useState([]);
  const [
    prevSelectedBusinessUnitSnapshot,
    setPrevSelectedBusinessUnitSnapshot,
  ] = useState(null);

  const [channel, setChannel] = useState(null);

  const [dataCenter, setDataCenter] = useState([]);

  const [sessionContext, setSessionContext] = useState(null);

  useEffect(() => {
    updateSessionContext();
  }, [selectedBrand, selectedBusinessUnit, userData]);

  const [userRoles, setUserRoles] = useState({
    sales: false,
    postSales: false,
    advanceSales: false,
    claims: false,
    viewAll: false,
  });

  const { showNotification } = useContext(NotificationContext);

  const apiStrapiUrl = process.env.REACT_APP_STRAPI_API;

  const fetchUserDataFromApi = async () => {
    try {
      const token = getToken();
      const apiUrl = `${apiStrapiUrl}users/me`;
      const response = await axios.get(apiUrl, {
        headers: { Authorization: `Bearer ${token}` },
      });
      const userData = response.data;
      setUserData(userData);
      setBrands(userData.brands || []);
      setDataCenter(userData.dataCenter || null);
      handleSetSelectedItems(userData);
      return userData;
    } catch (error) {
      handleError(error);
    }
  };

  const fetchShopId = async (shopId) => {
    try {
      const token = getToken();

      const params = {
        filters: {
          shopId: {
            $eq: shopId,
          },
        },
        populate: '*',
      };

      const buildQueryString = (params) => {
        const query = new URLSearchParams();
        for (const key in params.filters) {
          for (const operator in params.filters[key]) {
            query.append(
              `filters[${key}][${operator}]`,
              params.filters[key][operator],
            );
          }
        }
        if (params.populate) {
          query.append('populate', params.populate);
        }
        return query.toString();
      };

      const apiUrl = `${apiStrapiUrl}business-units?${buildQueryString(
        params,
      )}`;

      const response = await axios.get(apiUrl, {
        headers: { Authorization: `Bearer ${token}` },
      });

      const shopResponse = response.data;

      return shopResponse;
    } catch (error) {
      handleError(error);
    }
  };

  const handleSetSelectedItems = (userData) => {
    if (
      !userData ||
      !Array.isArray(userData.brands) ||
      userData.brands.length === 0
    ) {
      showNotification('COMMON.error.noAssignedBrands', 'error');
      clearSelection('brand');
      return;
    }
  
    const savedBrandCode = localStorage.getItem('selectedBrand');
    const savedBusinessUnitId = localStorage.getItem('selectedBusinessUnit');
  
    const selectedBrand =
      userData.brands.find((brand) => brand.code === savedBrandCode) ||
      userData.brands[0];
  
    setSelectedBrand(selectedBrand);
    localStorage.setItem('selectedBrand', selectedBrand.code);
  
    if (
      !Array.isArray(selectedBrand.channels) ||
      selectedBrand.channels.length === 0
    ) {
      showNotification('COMMON.error.noAssignedBusinessUnits', 'error');
      setBusinessUnits([]);
      setSelectedBusinessUnit([]);
      return;
    }
  
    const allBusinessUnits = selectedBrand.channels.flatMap(
      (channel) => channel.businessUnits || [],
    );
  
    if (allBusinessUnits.length === 0) {
      showNotification('COMMON.error.noAssignedBusinessUnits', 'error');
      setBusinessUnits([]);
      setSelectedBusinessUnit([]);
      return;
    }
  
    setBusinessUnits(allBusinessUnits);
  
    const selectedBusinessUnit =
      (savedBusinessUnitId &&
        allBusinessUnits.find(
          (unit) => unit.shopId === savedBusinessUnitId,
        )) ||
      allBusinessUnits[0];
    saveBusinessUnit(selectedBusinessUnit);
  
    setUserRoles({
      sales: checkUserSalesRole(selectedBrand, userData),
      postSales: userData.portalPermissions.some(
        (permission) => permission.key === ROLE.SALES_POST,
      ),
      advanceSales: userData.portalPermissions.some(
        (permission) => permission.key === ROLE.SALES_ADVANCE,
      ),
      claims: userData.portalPermissions.some(
        (permission) => permission.key === ROLE.CLAIMS,
      ),
      viewAll: userData.portalPermissions.some(
        (permission) => permission.key === ROLE.VIEW_ALL,
      ),
    });
  };

  const handleError = (error) => {
    if (error.response?.data?.error?.name === 'UnauthorizedError') {
      localStorage.removeItem('token');
      window.location.reload();
    }
    reportError(error);
  };

  const checkUserSalesRole = (brand, userData) => {
    return (
      userData.portalPermissions.some(
        (permission) => permission.key === ROLE.SALES,
      ) && brand.code !== BRAND.FCB
    );
  };

  const saveBusinessUnit = (businessUnit, addToList = false) => {
    if (businessUnit) {
      setSelectedBusinessUnit(businessUnit);
      localStorage.setItem('selectedBusinessUnit', businessUnit.shopId);

      if (addToList) {
        setBusinessUnits((businessUnits) => [...businessUnits, businessUnit]);
      }

    } else {
      showNotification('COMMON.error.noAssignedBusinessUnits', 'error');
      clearSelection('businessUnit');
    }
  };

  useEffect(() => {
    if (selectedBrand && (selectedBrand.length > 0 || Object.keys(selectedBrand).length > 0)){
      const selectedChannelObj = selectedBrand.channels.find((channelObj) =>
        (channelObj.businessUnits || []).some(
          (unit) => unit.shopId === selectedBusinessUnit.shopId,
        ),
      );
      setChannel(selectedChannelObj.channel);
    }
  }, [selectedBrand])

  const applyPrevBusinessUnitState = () => {
    if (!prevSelectedBusinessUnitSnapshot) return;

    const prevStateId = prevSelectedBusinessUnitSnapshot.id;
    setSelectedBusinessUnit(prevSelectedBusinessUnitSnapshot);
    setBusinessUnits((businessUnits) =>
      businessUnits.filter((unit) => unit.id === prevStateId),
    );
    clearPrevBusinessUnit() 
  };

  const saveBrand = (brand) => {
    setSelectedBrand(brand);
  
    if (
      !Array.isArray(brand.channels) ||
      brand.channels.length === 0
    ) {
      showNotification('COMMON.error.noAssignedBusinessUnits', 'error');
      setBusinessUnits([]);
      setSelectedBusinessUnit([]);
      setChannel(null);
      return;
    }
  
    const allBusinessUnits = brand.channels.flatMap(
      (channel) => channel.businessUnits || [],
    );
  
    if (allBusinessUnits.length === 0) {
      showNotification('COMMON.error.noAssignedBusinessUnits', 'error');
      setBusinessUnits([]);
      setSelectedBusinessUnit([]);
      setChannel(null);
      return;
    }
  
    setBusinessUnits(allBusinessUnits);
  
    const selectedBusinessUnit = allBusinessUnits[0];
    saveBusinessUnit(selectedBusinessUnit);
  
    setUserRoles((prevRoles) => ({
      ...prevRoles,
      sales: checkUserSalesRole(brand, userData),
    }));
    localStorage.setItem('selectedBrand', brand.code);
  };
  

  const savePrevBusinessUnit = (businessUnit) => {
    if (businessUnit) {
      setPrevSelectedBusinessUnitSnapshot(businessUnit);
    }
  };

  const clearPrevBusinessUnit = () => {
    setPrevSelectedBusinessUnitSnapshot(null);
  };

  const clearSelection = (type) => {
    switch (type) {
      case 'brand':
      default:
        setSelectedBrand([]);
        setBrands([]);
        localStorage.removeItem('selectedBrand');
        setBusinessUnits([]);
        setSelectedBusinessUnit([]);
        setChannel([]);
        localStorage.removeItem('selectedBusinessUnit');
        break;
      case 'businessUnit':
        setSelectedBusinessUnit([]);
        setBusinessUnits([]);
        setChannel([]);
        localStorage.removeItem('selectedBusinessUnit');
        break;
    }
  };

  const updateSessionContext = () => {
    const agentId = userData?.agentId ?? '';
    const brand = selectedBrand?.code ?? '';
    const config = selectedBusinessUnit?.brand ?? '';
    const businessUnit = selectedBusinessUnit?.shopId ?? '';
    const advanceSales =
      userData?.portalPermissions?.some(
        (permission) => permission.key === ROLE.SALES_ADVANCE,
      ) ?? false;

    const contextObject = {
      agentId,
      brand,
      businessUnit,
      config,
      advanceSales,
    };

    const contextString = btoa(JSON.stringify(contextObject));
    setSessionContext(contextString);
  };
  

  const value = React.useMemo(
    () => ({
      userData,
      selectedBusinessUnit,
      selectedBrand,
      businessUnits,
      brands,
      channel,
      dataCenter,
      userRoles,
      sessionContext,
      saveBusinessUnit,
      saveBrand,
      fetchUserDataFromApi,
      savePrevBusinessUnit,
      clearPrevBusinessUnit,
      prevSelectedBusinessUnitSnapshot,
      fetchShopId,
      applyPrevBusinessUnitState,
    }),
    [
      userData,
      selectedBusinessUnit,
      selectedBrand,
      businessUnits,
      brands,
      channel,
      dataCenter,
      userRoles,
      sessionContext,
      prevSelectedBusinessUnitSnapshot,
    ],
  );

  return (
    <UserDataContext.Provider value={value}>
      {children}
    </UserDataContext.Provider>
  );
};

UserDataProvider.propTypes = {
  children: PropTypes.node,
};
