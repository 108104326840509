import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import GenericTable from 'shared/components/GenericTable/genericTable';
import { format } from 'date-fns';
import { CONFIG_APP } from '../../../../shared/const/app.config';
import CurrencyComponent from '../../../../shared/components/Currency/Currency';
import { styled, alpha } from '@mui/material/styles';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import useModifyPolicy from './../../policyDetails/useModifyPolicy';
import SoftButton from '../../../../components/SoftButton';
import { UserDataContext } from 'shared/context/userDataContext';

const StyledMenu = styled((props) => <Menu elevation={0} {...props} />)(() => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    minWidth: 180,
    boxShadow: 'rgba(149, 157, 165, 0.2) 0px 0px 4px',
  },
}));

const PoliciesTable = ({ data, tableUuid }) => {
  const { t } = useTranslation();
  const { modifyPolicy } = useModifyPolicy();
  const {
    businessUnits,
    userData,
    selectedBusinessUnit,
    saveBusinessUnit,
    savePrevBusinessUnit,
    fetchShopId,
    selectedBrand,
    userRoles,
  } = useContext(UserDataContext);

  const navigate = useNavigate();
  const path = 'POLICIES';

  const composeUrl = (policyId, ...routes) => {
    const searchParams = new URLSearchParams();

    if (routes && Array.isArray(routes) && routes.length !== 0) {
      return `/policy-details/${policyId}/${routes.join(
        '/',
      )}?${searchParams.toString()}`;
    }

    return `/policy-details/${policyId}?${searchParams.toString()}`;
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selectedRow, setSelectedRow] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = ($event, rowValue) => {
    $event.stopPropagation();
    setAnchorEl($event.currentTarget);
    setSelectedRow(rowValue);
  };

  const handleClose = ($event, func = null) => {
    if ($event) $event.stopPropagation();

    if (func !== null && typeof func !== 'function') {
      throw new Error(
        `[Handle menu close] expected function, recived: ${typeof func} ${func}`,
      );
    }

    if (func !== null) {
      func(selectedRow);
    }

    setAnchorEl(null);
    setSelectedRow(null);
  };

  const ensurePolicyBrand = async (rowDetails) => {
    if (userRoles.viewALl && rowDetails.businessUnitId) {
      let addToList = false;
      let businessUnit =
        businessUnits.find(
          (businessUnit) => businessUnit.shopId === rowDetails.businessUnitId,
        ) || null;
      savePrevBusinessUnit(selectedBusinessUnit);

      if (!businessUnit) {
        const businessUnitResponse = await fetchShopId(
          rowDetails.businessUnitId,
        );
        if (!businessUnitResponse[0]) {
          throw new Error('[policies-tables] businessUnit id not found');
        }
        businessUnit = {
          ...businessUnitResponse[0],
          brand:
            businessUnitResponse[0]?.default_brand?.name ?? selectedBrand.brand,
        };
        addToList = true;
      }
      saveBusinessUnit(businessUnit, addToList);
    }
  };

  const goToViewPolicy = async (rowDetails) => {
    const { policyId } = rowDetails || {};

    if (!policyId) {
      throw new Error('[goToProfile] policyId is undefined');
    }

    await ensurePolicyBrand(rowDetails);

    const url = composeUrl(policyId);
    navigate(url);
  };

  const goToModifyPolicy = async (rowDetails) => {
    const { policyId } = rowDetails || {};

    if (!policyId) {
      throw new Error('[goToProfile] policyId is undefined');
    }
    await ensurePolicyBrand(rowDetails);

    await modifyPolicy({ policy: { policyNumber: policyId } });
  };

  const goToCancelPolicy = (rowDetails) => {
    const { policyId } = rowDetails || {};

    if (!policyId) {
      throw new Error('[goToProfile] policyId is undefined');
    }

    const url = composeUrl(policyId, 'cancel');
    navigate(url);
  };

  const columns = [
    ...(userRoles.viewAll
      ? [
          {
            label: `${t(`${path}.store`)}`,
            value: (policy) => policy.businessUnitId,
          },
        ]
      : []),
    {
      label: `${t(`${path}.policyId`)}`,
      value: (policy) =>
        `${policy.legacyPolicyId ? policy.legacyPolicyId : policy.policyId}`,
    },
    {
      label: t(`${path}.customer`),
      value: (policy) =>
        `${policy.customerFirstName} ${policy.customerLastName}`,
    },
    {
      label: t(`${path}.product`),
      value: (policy) => policy.productName || policy.product,
    },
    {
      label: t(`${path}.destination`),
      value: (policy) => policy.destination,
    },
    {
      label: t(`${path}.purchaseDate`),
      value: (policy) =>
        format(new Date(policy.purchaseDate), CONFIG_APP.DATE_FORMAT),
    },
    {
      label: t(`${path}.amount`),
      value: (policy) => (
        <CurrencyComponent
          value={policy.amount.amount}
          countryCode={policy.amount.currency}
        ></CurrencyComponent>
      ),
    },
    {
      label: t(`${path}.actions`),
      value: (rowValue) => (
        <>
          <SoftButton
            variant="outlined"
            color="secondary"
            onClick={($event) => handleClick($event, rowValue)}
            sx={{
              '&:hover': { borderColor: '#0a76ec' },
            }}
          >
            Actions
            <KeyboardArrowDownIcon
              sx={{
                transform: 'translateX(50%)',
              }}
            />
          </SoftButton>
          <StyledMenu
            anchorEl={anchorEl}
            open={open}
            onClose={($event) => handleClose($event, null)}
          >
            <MenuItem onClick={($event) => handleClose($event, goToViewPolicy)}>
              {t(`${path}.viewPolicy`)}
            </MenuItem>
            {userRoles.postSales && (
              <MenuItem
                onClick={($event) => handleClose($event, goToModifyPolicy)}
              >
                {t(`${path}.modifyPolicy`)}
              </MenuItem>
            )}
            {userRoles.postSales && (
              <MenuItem
                onClick={($event) => handleClose($event, goToCancelPolicy)}
              >
                {t(`${path}.cancelPolicy`)}
              </MenuItem>
            )}
          </StyledMenu>
        </>
      ),
      width: '70px',
    },
  ];

  return (
    <div data-testid="PoliciesTable">
      {!data || data.length === 0 ? (
        <p className="dataError">{t(`${path}.noPolicies`)}</p>
      ) : (
        <GenericTable
          tableUuid={tableUuid}
          data={data}
          columns={columns}
          path={path}
          onRowClick={(policy) => goToViewPolicy(policy)}
        />
      )}
    </div>
  );
};

PoliciesTable.propTypes = {
  data: PropTypes.array.isRequired,
  tableUuid: PropTypes.string,
};

PoliciesTable.defaultProps = {};

export default PoliciesTable;
